import { useNavigate } from "react-router-dom";
import { Container, Button, Typography, Box } from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft"; // Import the icon
import { AppBar, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { loginWithMicrosoftUrl } from "../../api/api";

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  //   const check_session = async () => {
  //     try {
  //       const data = await fetchUser();

  //       if (data.address) {
  //         navigate("/dashboard");
  //       }
  //     } catch (error) {
  //       console.log("Error checking session ", error);
  //     }
  //   };
  //   check_session();

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="fixed" // Changed from "static" to "fixed"
        sx={{ bgcolor: theme.palette.primary.main }}
        elevation={1}
      >
        <Toolbar>
          {/* <img
            src="/LogoLight.png"
            alt="Optimized Inc Logo"
            style={{
              height: "64px",
              marginRight: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = "/";
            }}
          /> */}
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            OPTIMIZED
          </Typography>
        </Toolbar>
      </AppBar>

      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* header at top with the logo */}

        <Container sx={{ width: "90%" }}>
          <Typography variant="h3" align="center" gutterBottom>
            Log in to Optimized
            {/* add a large 64x64 bolt icon */}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Button
              href={loginWithMicrosoftUrl}
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
            >
              <MicrosoftIcon sx={{ mr: 1 }} />
              Login with Outlook
            </Button>
          </Box>
          {/* <Box sx={{ mt: 1 }}>
            <Button
              href={loginWithGoogleUrl}
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
            >
              <GoogleIcon sx={{ mr: 1 }} />
              Login with Google
            </Button>
          </Box> */}
        </Container>
      </Container>
    </Box>
  );
};

export default Login;
