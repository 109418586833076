import React, { useState, useRef, useEffect, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar as MuiAppBar,
  Divider,
  Typography,
} from "@mui/material";

import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import Chatbot from "./Chat/Chatbot";
import IntakeForm from "./Drawer/IntakeForm";

import RequestManager from "./Drawer/RequestManager";
import { fetchUser, resetConversationId } from "../api/api";
import useFormConfig from "../hooks/useFormConfig"; // New custom hook
import useChat from "../hooks/useChat"; // New custom hook

import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

// Remove the fixed drawerWidth
// const drawerWidth = "60%";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: `-${drawerWidth}`,
  position: "relative",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

function Dashboard() {
  const navigate = useNavigate(); // Initialize useNavigate

  const {
    messages,
    setMessages,
    drawerOpen,
    setDrawerOpen,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    initialOptions,
    context,
    setContext,
  } = useChat();

  const { formEndRef, latestUpdateId, setLatestUpdateId } = useFormConfig();

  const [allFiles, setAllFiles] = useState([]);

  const [drawerLoadingCount, setDrawerLoadingCount] = useState(0);
  const [chatbotLoading, setChatbotLoading] = useState(false);
  const handleDrawerUpdate = async (data) => {
    console.log("Data: ", data);
  };

  const [user, setUser] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const data = await fetchUser();
      setUser(data);

      return data;
    } catch (error) {
      console.error("Error fetching user:", error);
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserData().then((user) => {
      console.log("USER: ", user);
    });
  }, [fetchUserData]);

  // Determine drawerWidth based on activePolicy
  const drawerWidth = activePolicy === "punchout" ? "60%" : "50%";

  // New useEffect to open the drawer when there are at least 4 messages
  useEffect(() => {
    if (activePolicy === "intake") {
      setDrawerOpen(true);
    } else if (
      activePolicy === "manage_request" &&
      context.manage_request.open_request
    ) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  }, [messages, setDrawerOpen, activePolicy]);

  useEffect(() => {}, [setDrawerOpen, activePolicy]);

  useEffect(() => {
    resetConversationId();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Main open={drawerOpen} drawerWidth={drawerWidth}>
          <Box
            sx={{
              width: drawerOpen ? "100%" : "80%",
              height: "100%",
              mx: "auto",
              mb: 2,
            }}
          >
            <Chatbot
              user={user}
              formEndRef={formEndRef}
              drawerLoadingCount={drawerLoadingCount}
              setDrawerLoadingCount={setDrawerLoadingCount}
              latestUpdateId={latestUpdateId}
              setLatestUpdateId={setLatestUpdateId}
              context={context}
              setContext={setContext}
              conversationOver={conversationOver}
              setConversationOver={setConversationOver}
              allFiles={allFiles}
              setAllFiles={setAllFiles}
              messages={messages}
              setMessages={setMessages}
              setOpen={setDrawerOpen}
              handleDrawerUpdate={handleDrawerUpdate} // Pass handleDrawerUpdate to Chatbot
              activePolicy={activePolicy}
              setActivePolicy={setActivePolicy}
              initialOptions={initialOptions}
              chatbotLoading={chatbotLoading}
              setChatbotLoading={setChatbotLoading}
            />
          </Box>
        </Main>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={drawerOpen}
        >
          <Divider />
          {activePolicy === "intake" && context.intake && (
            <IntakeForm
              context={context}
              setContext={setContext}
              conversationOver={conversationOver}
              setConversationOver={setConversationOver}
              allFiles={allFiles}
              setAllFiles={setAllFiles}
              chatbotLoading={chatbotLoading}
            />
          )}
          {activePolicy === "manage_request" &&
            context.manage_request.open_request && (
              <RequestManager
                context={context}
                setContext={setContext}
                conversationOver={conversationOver}
                setConversationOver={setConversationOver}
                allFiles={allFiles}
                setAllFiles={setAllFiles}
                chatbotLoading={chatbotLoading}
              />
            )}
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
