// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Auth/Login";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "./theme";
import { Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Onboarding from "./components/Auth/Onboarding";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
