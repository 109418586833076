import React from "react";
import {
  Box,
  Fade,
  Typography,
  CircularProgress,
  Button,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { marked } from "marked";
import FileAttachment from "./Files/FileAttachment";

const MessageList = ({
  user,
  messages,
  toolRunningMessages,
  theme,
  messagesEndRef,
  chatbotLoading,
  sendMessage,
  submittedSuccessfully,
  initialOptions,
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "auto", // Ensures scrollbar appears only when needed
        mb: 2,
        px: 2,
      }}
    >
      {messages.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Change to column to stack elements
            justifyContent: "center",
            alignItems: "center", // Center vertically
            height: "80%", // Ensure the container takes full height

            overflow: "hidden",
          }}
        >
          <Typography variant="h4" sx={{ width: "100%", textAlign: "center" }}>
            {user?.name
              ? `Welcome, ${user?.name.split(" ")[0]}! What can I help with?`
              : "Welcome! What can I help with?"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap", // Allow options to wrap if needed
              mt: 2, // Add some margin-top for spacing
            }}
          >
            {initialOptions.map((option, index) => (
              <Button
                key={index}
                onClick={() => sendMessage(option.message, [])}
                disabled={chatbotLoading || submittedSuccessfully}
                variant="outlined"
                sx={{
                  p: 2,
                  m: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "25%", // Set width to 20%
                  minHeight: "10rem", // Set height to 25%
                  textTransform: "none", // Add this line to prevent text capitalization
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  {option.icon}
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    textAlign: "left",
                  }}
                >
                  {option.description}
                </Typography>
              </Button>
            ))}
          </Box>
        </Box>
      ) : (
        messages.map(
          (msg, index) =>
            msg.flag !== "hidden" && (
              <Fade in={true} timeout={1000} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems:
                      msg.role === "assistant" && !msg.tool_calls
                        ? "flex-start"
                        : msg.role === "user"
                        ? "flex-end"
                        : "center",
                  }}
                >
                  {msg.role === "assistant" && msg.tool_calls ? (
                    Array.isArray(msg.tool_calls) &&
                    msg.tool_calls.length > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          color: theme.palette.text.disabled,
                          my: 0,
                        }}
                      >
                        {(() => {
                          // Aggregate tool calls by name
                          const aggregatedTools = msg.tool_calls.reduce(
                            (acc, tool) => {
                              const message = toolRunningMessages[tool.name];
                              if (message) {
                                acc[message] = (acc[message] || 0) + 1;
                              }
                              return acc;
                            },
                            {}
                          );

                          // Render aggregated messages vertically
                          return Object.entries(aggregatedTools).map(
                            ([message, count], idx) => (
                              <Box
                                key={idx}
                                sx={{
                                  textAlign: "center",
                                  my: 1,
                                  width: "100%", // Ensure full width for vertical stacking
                                }}
                              >
                                <Typography variant="body2" fontWeight="bold">
                                  {message}
                                  {count > 1 ? ` (x${count})` : ""}
                                </Typography>
                              </Box>
                            )
                          );
                        })()}
                      </Box>
                    ) : null
                  ) : msg.role === "assistant" &&
                    msg.flag === "notification" ? (
                    <Box
                      sx={{
                        display: "flex",
                        color: theme.palette.text.disabled,
                        width: "100%",
                        justifyContent: "center",
                        my: 2,
                      }}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        {msg.content}
                      </Typography>
                    </Box>
                  ) : msg.role === "tool" ? (
                    toolRunningMessages[msg.tool_name] ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          my: 1,
                        }}
                      >
                        {msg.tool_name === "get_relevant_vendors" &&
                          JSON.parse(msg.content.replace(/'/g, '"')).map(
                            (vendor, idx) => (
                              <Button
                                key={idx}
                                onClick={() => {
                                  if (!chatbotLoading) {
                                    const formattedVendor = vendor
                                      .replace(/_/g, " ")
                                      .replace(/\b\w/g, (c) => c.toUpperCase());
                                    sendMessage(
                                      `I prefer ${formattedVendor}`,
                                      []
                                    );
                                  }
                                }}
                                disabled={
                                  chatbotLoading || submittedSuccessfully
                                }
                                sx={{ p: 1, m: 1 }}
                              >
                                <img
                                  src={`/vendors/${vendor}.svg`}
                                  alt={vendor}
                                  onError={(e) => {
                                    console.log(
                                      "Error loading vendor image: ",
                                      vendor
                                    );
                                    e.target.onerror = null;
                                    e.target.src = "/vendors/Default.svg";
                                  }}
                                  style={{ width: 96, height: 96 }}
                                />
                              </Button>
                            )
                          )}
                        {msg.tool_name === "search_company_policy" && (
                          <Accordion
                            sx={{
                              my: 0,
                              pt: 1,
                              border: `4px solid ${theme.palette.success.main}`,

                              backgroundColor: theme.palette.background.paper,
                            }}
                            elevation={0}
                            disableGutters
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <AlertTitle
                                sx={{ color: theme.palette.success.main }}
                              >
                                Company Intake Policy
                              </AlertTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                  __html: marked(`${msg.content}`),
                                }}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {msg.tool_name === "get_requests_list" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <List
                              sx={{
                                overflow: "auto",
                                maxHeight: "23rem",
                                border: `2px solid ${theme.palette.background.dark}`,
                                borderRadius: 2,
                                py: 0,
                              }}
                            >
                              {msg.artifact.map((request, idx) => (
                                <ListItem key={idx} disablePadding>
                                  <ListItemButton
                                    onClick={() =>
                                      sendMessage(
                                        `I want to know the status of request ${request.id}`,
                                        []
                                      )
                                    }
                                    disabled={
                                      chatbotLoading || submittedSuccessfully
                                    }
                                    sx={{
                                      p: 1,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AssignmentIcon sx={{ mx: 1 }} />{" "}
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="body2"
                                          fontWeight="bold"
                                          sx={{ ml: 1 }}
                                        >
                                          {request.title}
                                        </Typography>
                                      }
                                      secondary={
                                        <Typography
                                          variant="body2"
                                          sx={{ ml: 1 }}
                                        >
                                          ID: {request.id}
                                        </Typography>
                                      }
                                    />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        )}
                      </Box>
                    ) : null
                  ) : (
                    <Typography
                      variant="body"
                      sx={{
                        backgroundColor:
                          msg.role === "assistant"
                            ? theme.palette.primary.main
                            : theme.palette.background.dark,
                        color:
                          msg.role === "assistant"
                            ? theme.palette.background.default
                            : theme.palette.text.primary,
                        py: 0,
                        px: 2,
                        my: 1,
                        borderRadius: "12px",
                        maxWidth: "75%",
                        "& a": {
                          color: "lightblue", // Set link color to light blue
                        },
                      }}
                    >
                      <div
                        className="markdown-content"
                        dangerouslySetInnerHTML={{
                          __html: marked(msg.content),
                        }}
                      />
                    </Typography>
                  )}
                  {msg.role === "user" && msg.files && msg.files.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        mt: 0,
                      }}
                    >
                      {msg.files.map((file, idx) => (
                        <FileAttachment
                          key={idx}
                          file={file}
                          deletable={false}
                          theme={theme}
                        />
                      ))}
                    </Box>
                  ) : null}
                </Box>
              </Fade>
            )
        )
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 5,
          height: 48,
        }}
      >
        {chatbotLoading && <CircularProgress size={48} />}
      </Box>
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default MessageList;
