import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  CircularProgress,
  LinearProgress,
  Dialog,
  Alert,
  AlertTitle,
  Divider,
  Pagination,
} from "@mui/material";
import { updateRequest, cancelRequest } from "../../api/api";
import FileAttachments from "../Chat/Files/FileAttachments";
import { useTheme } from "@mui/material/styles";
import FlowDiagram from "../Chat/Diagrams/FlowDiagram";
import FormDisplay from "./FormDisplay";
import { getErrors } from "../../utils/formUtils";
const RequestManager = ({
  context,
  setContext,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  chatbotLoading,
}) => {
  const [localFormConfig, setLocalFormConfig] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(
    context.manage_request.open_request.forms.length
  );
  const [isModified, setIsModified] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(""); // "update" or "cancel"

  const theme = useTheme();

  useEffect(() => {
    if (context.manage_request) {
      const forms = context.manage_request.open_request.forms;
      const selectedForm = forms[selectedVersion - 1]?.form || {};
      setLocalFormConfig(selectedForm);
      setFadeIn(true);
    }
  }, [context.manage_request, selectedVersion]);

  const handleVersionChange = (event, value) => {
    setSelectedVersion(value);
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { newErrors, newErrorFields } = getErrors(localFormConfig);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setErrorFields(newErrorFields);
      setOpenDialog(true);
      setLoading(false);
      return;
    }

    console.log("allFiles: ", allFiles);
    console.log("localFormConfig: ", localFormConfig);

    try {
      const response = await updateRequest(
        context.manage_request.open_request.id,
        localFormConfig,
        allFiles.map((file) => file.name)
      ); // Use the new function
      if (response.success) {
        console.log("Form Updated: ", localFormConfig);
        setContext({
          ...context,
          manage_request: {
            ...context.manage_request,
            open_request: response.updated_request,
          },
        });
        setSelectedVersion(response.updated_request.forms.length);
        setConversationOver(true);
      } else {
        console.error("Error updating form:", response.message);
        setDialogMessage(response.message);
        setDialogTitle("Error updating form!");
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setDialogMessage("Error submitting form. Please try again.");
      setDialogTitle("Error submitting form!");
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorFields([]);
  };

  const handleSubmitCancel = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await cancelRequest(
        context.manage_request.open_request.id
      );
      if (response.success) {
        console.log(
          "Request Cancelled: ",
          context.manage_request.open_request.id
        );
        setContext({
          ...context,
          manage_request: {
            ...context.manage_request,
            open_request: response.updated_request,
          },
        });
        setDialogMessage("The request has been cancelled successfully.");
        setDialogTitle("Request cancelled!");
        setOpenDialog(true);
        setConversationOver(true);
      } else {
        console.error("Error cancelling the request:", response.message);
        setDialogMessage(response.message);
        setDialogTitle("Error cancelling the request!");
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error cancelling the request:", error);
      setDialogMessage("Error cancelling the request. Please try again.");
      setDialogTitle("Error cancelling the request!");
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAction = (e) => {
    setConfirmDialogOpen(false);
    if (dialogType === "cancel") {
      handleSubmitCancel(e);
    } else if (dialogType === "update") {
      handleSubmitUpdate(e);
    }
  };

  return (
    <Box
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.dark",
        color: "text.main",
        pt: 1,
        pb: 2,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Box sx={{ width: "100%", mt: 0 }}>
          {chatbotLoading && (
            <LinearProgress sx={{ width: "70%", mx: "auto", height: 2 }} />
          )}
        </Box>

        <Box
          sx={{
            width: "90%",
            height: "100%",
          }}
        >
          {context.manage_request.open_request && (
            <FlowDiagram
              data={context.manage_request.open_request}
              theme={theme}
            />
          )}
        </Box>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          mb: 0,
          px: 4,
        }}
      >
        <Typography variant="subtitle1">Form Versions:</Typography>
        <Pagination
          count={
            context.manage_request.open_request.forms
              ? context.manage_request.open_request.forms.length
              : 1
          }
          page={selectedVersion}
          onChange={handleVersionChange}
          color="primary"
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
      <Box
        elevation={0}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.dark",
          color: "text.main",
          pt: 1,
          pb: 2,
          overflow: "hidden",
        }}
      >
        {(() => {
          try {
            return (
              <FormDisplay
                formConfig={localFormConfig}
                setFormConfig={setLocalFormConfig}
                handleFormUpdate={() => {}}
                chatbotLoading={chatbotLoading}
                disabled={
                  loading ||
                  conversationOver ||
                  selectedVersion !==
                    context.manage_request.open_request.forms.length
                }
                setIsModified={setIsModified}
              />
            );
          } catch (error) {
            console.error("Error rendering FormDisplay:", error);
            return null;
          }
        })()}

        <Box
          sx={{
            width: "90%",
            mx: "auto",
            my: 1,
          }}
        >
          <FileAttachments
            selectedFiles={[]}
            setSelectedFiles={() => {}}
            // theme={theme}
          />

          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Grid item xs={6} sx={{ px: 2 }}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  setDialogType("cancel");
                  setConfirmDialogOpen(true);
                }}
                disabled={
                  loading ||
                  conversationOver ||
                  context.manage_request.open_request.status === "cancelled"
                }
              >
                Cancel Request
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ px: 2 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                onClick={() => {
                  setDialogType("update");
                  setConfirmDialogOpen(true);
                }}
                disabled={loading || conversationOver || !isModified}
              >
                {loading ? <CircularProgress size={24} /> : "Update Request"}
              </Button>
            </Grid>
          </Grid>
        </Box>

        {openDialog && (
          <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ p: 10 }}>
            <Alert severity="error" sx={{ p: 2 }}>
              <AlertTitle>{dialogTitle}</AlertTitle>
              {dialogMessage || "Please fill up the following fields:"}
              <ul>
                {errorFields.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </ul>
            </Alert>
          </Dialog>
        )}

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
        >
          <Alert
            severity={dialogType === "update" ? "info" : "error"}
            sx={{ pr: 4, py: 2 }}
          >
            <AlertTitle sx={{ mb: 1 }}>
              {dialogType === "update"
                ? "Confirm Update"
                : "Confirm Cancellation"}
            </AlertTitle>
            {dialogType === "update"
              ? "Are you sure you want to update the request?"
              : "Are you sure you want to cancel the request?"}
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => setConfirmDialogOpen(false)}
                color="inherit"
              >
                No
              </Button>
              <Button onClick={handleConfirmAction} color="primary" autoFocus>
                Yes
              </Button>
            </Box>
          </Alert>
        </Dialog>
      </Box>
    </Box>
  );
};

export default RequestManager;
