import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const API_URL = process.env.REACT_APP_API_URL;

// Function to generate a new Conversation ID
const getNewConversationId = () => uuidv4();

// Initialize conversationId at runtime
let conversationId = getNewConversationId();

export const loginWithMicrosoftUrl = `${API_URL}/auth/login/microsoft`;

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Include credentials (cookies) with requests
});

// Function to reset Conversation ID
export const resetConversationId = () => {
  conversationId = getNewConversationId();
  console.log("Conversation ID Reset:", conversationId);
};

// Call resetConversationId on module load to ensure it's fresh
resetConversationId();

export const initializeChat = async () => {
  try {
    const response = await axiosInstance.get(`/initialize-chat`, {
      headers: {
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching initial message:", error);
    throw error;
  }
};

export const sendMessageToChatbot = async (formData) => {
  try {
    const response = await axiosInstance.post(`/chat`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const updateFormConfig = async (data) => {
  try {
    const response = await axiosInstance.post(`/update-form`, data, {
      headers: {
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating form config:", error);
    throw error;
  }
};

export const processFiles = async (formData) => {
  try {
    const response = await axiosInstance.post(`/process-files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error processing files:", error);
    throw error;
  }
};

export const submitForm = async (formConfig, filesNames) => {
  try {
    const response = await axiosInstance.post(
      `/submit-form`,
      {
        intake: {
          form: formConfig,
          files_names: filesNames,
        },
      },
      {
        headers: {
          "Conversation-ID": conversationId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};
export const logout = async () => {
  try {
    await axiosInstance.get(`/auth/logout`);
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
};

export const updateRequest = async (requestId, formData) => {
  try {
    const response = await axiosInstance.post(
      `/update-request`,
      {
        request_id: requestId,
        form: formData,
      },
      {
        headers: { "Conversation-ID": conversationId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating request:", error);
    throw error;
  }
};

export const cancelRequest = async (requestId) => {
  try {
    const response = await axiosInstance.post(
      `/cancel-request`,
      {
        request_id: requestId,
      },
      {
        headers: { "Conversation-ID": conversationId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error cancelling request:", error);
    throw error;
  }
};

export const fetchUser = async () => {
  try {
    const response = await axios.get(`${API_URL}/auth/get_user`, {
      withCredentials: true,
    });
    console.log("USER ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching user: ", error.response);
    throw error;
  }
};

export const onboardUser = async () => {
  const response = await axios.post(
    `${API_URL}/auth/onboard`,
    {},
    { withCredentials: true }
  );
  return response;
};
