import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  CircularProgress,
  LinearProgress,
  Dialog,
  Alert,
  AlertTitle,
} from "@mui/material";
import EditableTable from "./EditableTable"; // Import the EditableTable component
import { submitForm } from "../../api/api";
import FileAttachments from "../Chat/Files/FileAttachments";
import { useTheme } from "@mui/material/styles";
import FormDisplay from "./FormDisplay";
import { getErrors } from "../../utils/formUtils";

const IntakeForm = ({
  context,
  setContext,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  chatbotLoading,
}) => {
  const [localFormConfig, setLocalFormConfig] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (context.intake) {
      setLocalFormConfig(context.intake.form);
      setFadeIn(true);
    }
  }, [context.intake]);

  const handleFormUpdate = (updatedFormConfig) => {
    setContext({
      ...context,
      intake: { ...context.intake, form: updatedFormConfig },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let { newErrors, newErrorFields } = getErrors(localFormConfig);

    console.log("newErrors: ", newErrors);
    console.log("newErrorFields: ", newErrorFields);
    setErrors(newErrors);
    setErrorFields(newErrorFields);

    if (Object.keys(newErrors).length > 0) {
      console.log("opening dialog", Object.keys(newErrors).length);
      setOpenDialog(true);
      setLoading(false);
      return;
    }

    console.log("allFiles: ", allFiles);
    console.log("localFormConfig: ", localFormConfig);

    try {
      const response = await submitForm(
        localFormConfig,
        allFiles.map((file) => file.name)
      ); // Use the new function
      console.log("Response: ", response);
      if (response.success) {
        console.log("Form Data Submitted: ", localFormConfig);
        setConversationOver(true);
      } else {
        console.error("Error submitting form:", response.message);
        setDialogMessage(response.message);
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setDialogMessage("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorFields([]);
  };

  return (
    <Box
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.dark",
        color: "text.main",
        pt: 4,
        pb: 2,
        overflow: "hidden",
      }}
    >
      {(() => {
        try {
          return (
            <FormDisplay
              formConfig={localFormConfig}
              setFormConfig={setLocalFormConfig}
              handleFormUpdate={handleFormUpdate}
              chatbotLoading={chatbotLoading}
              setIsModified={() => {}}
            />
          );
        } catch (error) {
          console.error("Error rendering FormDisplay:", error);
          return null;
        }
      })()}
      <Box
        sx={{
          width: "90%",
          mx: "auto",
          mb: 2,
        }}
      >
        <FileAttachments
          selectedFiles={allFiles}
          setSelectedFiles={setAllFiles}
          theme={theme}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          onClick={handleSubmit}
          disabled={loading || conversationOver}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>
      {openDialog && (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ p: 10 }}>
          <Alert severity="error" sx={{ p: 2 }}>
            <AlertTitle>Can't submit form!</AlertTitle>
            {dialogMessage || "Please fill up the following fields:"}
            <ul>
              {errorFields.map((field, index) => (
                <li key={index}>{field}</li>
              ))}
            </ul>
          </Alert>
        </Dialog>
      )}
    </Box>
  );
};

export default IntakeForm;
