import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  CircularProgress,
  LinearProgress,
  Dialog,
  Alert,
  AlertTitle,
  Tooltip,
} from "@mui/material";
import EditableTable from "./EditableTable";
import { useTheme } from "@mui/material/styles";
import { getVisibleSections } from "../../utils/formUtils";
const FormDisplay = ({
  formConfig,
  setFormConfig,
  handleFormUpdate,
  chatbotLoading,
  conversationOver,
  disabled = false,
  selectedVersion,
  setIsModified,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (formConfig && formConfig.length > 0) {
      setFadeIn(true);
    }
  }, [formConfig]);

  const visibleSections = getVisibleSections(formConfig);

  const handleChange = (e, sectionIndex, fieldIndex) => {
    const { value } = e.target;
    const updatedFormConfig = [...formConfig];
    console.log("updatedFormConfig", updatedFormConfig);

    console.log("sectionIndex", sectionIndex);
    console.log("fieldIndex", fieldIndex);
    const field = updatedFormConfig[sectionIndex].fields[fieldIndex];

    console.log("field", field);
    if (
      field.type === "table" &&
      field.rowIndex !== undefined &&
      field.columnIndex !== undefined
    ) {
      updatedFormConfig[sectionIndex].fields[fieldIndex].value[field.rowIndex][
        field.columnIndex
      ] = value;
    } else {
      updatedFormConfig[sectionIndex].fields[fieldIndex].value = value;
    }

    // Clear the error for the modified field
    const updatedErrors = { ...errors };
    if (
      updatedErrors[sectionIndex] &&
      updatedErrors[sectionIndex][fieldIndex]
    ) {
      delete updatedErrors[sectionIndex][fieldIndex];
      if (Object.keys(updatedErrors[sectionIndex]).length === 0) {
        delete updatedErrors[sectionIndex];
      }
    }

    setErrors(updatedErrors);
    setFormConfig(updatedFormConfig);
    handleFormUpdate(updatedFormConfig);
    setIsModified(true);
    console.log("updatedFormConfig", updatedFormConfig);

    setFadeIn(false);
    setTimeout(() => setFadeIn(true), 100);
  };

  const handleTableRowsChange = (sectionIndex, fieldIndex, updatedRows) => {
    const updatedFormConfig = [...formConfig];
    updatedFormConfig[sectionIndex].fields[fieldIndex].value = updatedRows;
    setFormConfig(updatedFormConfig);
    handleFormUpdate(updatedFormConfig);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorFields([]);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 0,
        }}
      >
        {/* <Box sx={{ width: "100%", mt: 1 }}>
          {chatbotLoading && (
            <LinearProgress sx={{ width: "70%", mx: "auto", height: 5 }} />
          )}
        </Box> */}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          px: 4,
          py: 0,
          mb: 0,
        }}
      >
        <form>
          {formConfig.map((section, sectionIndex) => {
            const visibleTitles = visibleSections.map((s) => s.title);
            const isVisible = visibleTitles.includes(section.title);
            if (!isVisible) {
              return null;
            }
            return (
              <Fade in={fadeIn} timeout={1000} key={sectionIndex}>
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                    {section.title}
                  </Typography>
                  <Grid container spacing={2}>
                    {section.fields.map((field, fieldIndex) => {
                      const hasError =
                        errors[sectionIndex] &&
                        errors[sectionIndex][fieldIndex];

                      // Determine if Tooltip should be used
                      const InputField = () => {
                        switch (field.type) {
                          case "table":
                            return (
                              <EditableTable
                                rows={field.value}
                                columns={field.columns}
                                onRowsChange={(updatedRows) =>
                                  handleTableRowsChange(
                                    sectionIndex,
                                    fieldIndex,
                                    updatedRows
                                  )
                                }
                                disabled={
                                  chatbotLoading || conversationOver || disabled
                                }
                              />
                            );

                          case "select":
                            return (
                              <FormControl fullWidth error={hasError}>
                                <InputLabel>{field.name}</InputLabel>
                                <Select
                                  label={field.name}
                                  name={String(fieldIndex)}
                                  value={field.value || ""}
                                  onChange={(e) =>
                                    handleChange(e, sectionIndex, fieldIndex)
                                  }
                                  displayEmpty
                                  disabled={
                                    chatbotLoading ||
                                    conversationOver ||
                                    disabled
                                  }
                                  sx={{
                                    backgroundColor: "background.default",
                                    borderRadius: "12px",
                                  }}
                                >
                                  {Array.isArray(field.options)
                                    ? field.options.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))
                                    : Object.keys(field.options).map(
                                        (option, index) => (
                                          <MenuItem key={index} value={option}>
                                            {option}
                                          </MenuItem>
                                        )
                                      )}
                                </Select>
                              </FormControl>
                            );

                          case "textarea":
                            return (
                              <TextField
                                fullWidth
                                label={field.name}
                                name={String(fieldIndex)}
                                type="text"
                                value={field.value || ""}
                                onChange={(e) =>
                                  handleChange(e, sectionIndex, fieldIndex)
                                }
                                multiline
                                rows={4}
                                InputLabelProps={{
                                  shrink: Boolean(field.value),
                                }}
                                disabled={
                                  chatbotLoading || conversationOver || disabled
                                }
                                error={hasError}
                                helperText={hasError ? "" : ""}
                                sx={{
                                  backgroundColor: "background.default",
                                  borderRadius: "12px",
                                }}
                              />
                            );

                          case "text":
                          case "number":
                          case "date":
                            return (
                              <TextField
                                fullWidth
                                label={field.name}
                                name={String(fieldIndex)}
                                type={field.type}
                                value={field.value || ""}
                                onChange={(e) =>
                                  handleChange(e, sectionIndex, fieldIndex)
                                }
                                InputLabelProps={{
                                  shrink:
                                    field.type === "date" ||
                                    field.type === "datetime-local" ||
                                    Boolean(field.value),
                                }}
                                disabled={
                                  chatbotLoading || conversationOver || disabled
                                }
                                sx={{
                                  backgroundColor: "background.default",
                                  borderRadius: "12px",
                                }}
                                error={hasError}
                                helperText={hasError ? "" : ""}
                              />
                            );

                          default:
                            return null;
                        }
                      };

                      return (
                        <Fade in={fadeIn} timeout={500} key={fieldIndex}>
                          <Grid
                            item
                            xs={12}
                            sm={
                              field.type === "textarea" ||
                              field.type === "table" ||
                              (field.name && field.name.length > 50)
                                ? 12
                                : 6
                            }
                          >
                            {field.info ? (
                              <Tooltip
                                title={
                                  <Typography
                                    variant="small"
                                    sx={{ whiteSpace: "pre-line" }}
                                  >
                                    {field.info}
                                  </Typography>
                                }
                                arrow
                                placement="left-start"
                              >
                                <div>
                                  <InputField />
                                </div>
                              </Tooltip>
                            ) : (
                              <InputField />
                            )}
                          </Grid>
                        </Fade>
                      );
                    })}
                  </Grid>
                </Box>
              </Fade>
            );
          })}
        </form>
      </Box>
    </>
  );
};

export default FormDisplay;
