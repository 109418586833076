import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import FileAttachments from "./Files/FileAttachments";
import toolRunningMessages from "../../utils/toolRunningMessages.json";
import { sendMessageToChatbot } from "../../api/api";

const Chatbot = ({
  user,
  context,
  setContext,
  setLatestUpdateId,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  messages,
  setMessages,
  activePolicy,
  setActivePolicy,
  initialOptions,
  chatbotLoading,
  setChatbotLoading,
}) => {
  const [input, setInput] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const dragCounter = useRef(0);

  const theme = useTheme();
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null); // Reference to the input field

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Drag and Drop Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    dragCounter.current = 0;

    const newFiles = Array.from(e.dataTransfer.files).filter(
      (file) => file.type === "application/pdf"
    );

    // Check for duplicate file names
    const existingFileNames = selectedFiles.map((file) => file.name);
    const duplicates = newFiles.filter((file) =>
      existingFileNames.includes(file.name)
    );

    if (duplicates.length > 0) {
      setDuplicateFiles(duplicates.map((file) => file.name));
      setOpenDuplicateModal(true);
    }

    // Filter out duplicates before adding
    const uniqueFiles = newFiles.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    if (selectedFiles.length + uniqueFiles.length > 3) {
      setOpenModal(true);
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
    setDuplicateFiles([]);
  };

  const sendMessage = async (message, files) => {
    const currentUpdateId = Date.now();
    setLatestUpdateId(currentUpdateId);
    setChatbotLoading(true);

    let newMessages = [
      {
        role: "user",
        content: message,
        files: files.map((file) => ({ name: file.name, type: file.type })),
      },
    ];
    let allNewMessages = [...messages, ...newMessages];

    setMessages(allNewMessages);

    const formData = new FormData();
    formData.append("newMessages", JSON.stringify(newMessages));

    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
    }

    formData.append("activePolicy", activePolicy);
    formData.append("context", JSON.stringify(context));

    let isConversationOver = conversationOver; // Initialize local variable

    try {
      let continueSending = true;
      let currentActivePolicy = activePolicy;

      while (continueSending) {
        continueSending = false;

        const { newMessages, isDone, newActivePolicy, newContext } =
          await sendMessageToChatbot(formData);

        console.log("NEW ACTIVE AGENT: ", newActivePolicy);
        console.log("isDone: ", isDone);
        console.log("NEW CONTEXT: ", newContext);

        if (isDone) {
          setConversationOver(true);
          isConversationOver = true; // Update local variable
        }

        if (newActivePolicy && newActivePolicy !== currentActivePolicy) {
          setActivePolicy(newActivePolicy);
          currentActivePolicy = newActivePolicy;
          formData.set("activePolicy", newActivePolicy);
          console.log("Current active agent: ", currentActivePolicy);
          continueSending = true;
        }

        setContext(newContext);

        allNewMessages = [...allNewMessages, ...newMessages];
        setMessages(allNewMessages);
        console.log("ALL NEW MESSAGES: ", allNewMessages);

        formData.set("newMessages", JSON.stringify([]));
        formData.set("context", JSON.stringify(newContext));

        const lastMessage = newMessages[newMessages.length - 1];

        if (lastMessage && lastMessage.role == "tool") {
          continueSending = true;
        }
      }
      setChatbotLoading(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setChatbotLoading(false);
      setSelectedFiles([]);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (!chatbotLoading && inputRef.current) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // Adjust the delay as needed
    }
  }, [chatbotLoading]);

  return (
    <Box
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        mx: 2,
        flexDirection: "column",
        pt: 4,
        overflow: "hidden",
        position: "relative",
        border: dragging ? `2px dashed ${theme.palette.primary.main}` : "none",
        backgroundColor: dragging ? theme.palette.action.hover : "inherit",
        transition: "background-color 0.3s, border 0.3s",
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <MessageList
        user={user}
        messages={messages}
        toolRunningMessages={toolRunningMessages}
        theme={theme}
        messagesEndRef={messagesEndRef}
        chatbotLoading={chatbotLoading}
        sendMessage={sendMessage}
        submittedSuccessfully={false} // Adjust as needed
        initialOptions={initialOptions}
      />
      {conversationOver ? (
        <Alert severity="success" variant="filled" sx={{ mb: 2, mt: 2 }}>
          <AlertTitle>Conversation Over</AlertTitle>
          Your conversation with the chatbot is over. You can close this window
          now.
        </Alert>
      ) : (
        <>
          <FileAttachments
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            theme={theme}
          />

          <MessageInput
            input={input}
            setInput={setInput}
            sendMessage={sendMessage}
            chatbotLoading={chatbotLoading}
            theme={theme}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            conversationOver={conversationOver}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
            inputRef={inputRef} // Pass the ref to focus the input
          />
        </>
      )}

      {/* Modal for File Upload Limit Exceeded */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          You can only upload up to 3 files at a time. Please upload new files
          in a separate message.
        </DialogTitle>
      </Dialog>

      {/* Modal for Duplicate Files */}
      <Dialog open={openDuplicateModal} onClose={handleCloseDuplicateModal}>
        <DialogTitle>The following file(s) are already attached:</DialogTitle>
        <DialogContent>
          <ul>
            {duplicateFiles.map((fileName, idx) => (
              <li key={idx}>{fileName}</li>
            ))}
          </ul>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Chatbot;
