import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const MessageInput = ({
  input,
  setInput,
  sendMessage,
  chatbotLoading,
  theme,
  selectedFiles,
  setSelectedFiles,
  conversationOver,
  allFiles,
  setAllFiles,
  inputRef, // Receive the ref from Chatbot.js
}) => {
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState([]);

  const handleSend = () => {
    sendMessage(input, selectedFiles);
    setInput("");
    setSelectedFiles([]);
    setAllFiles([...allFiles, ...selectedFiles]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).filter(
      (file) => file.type === "application/pdf"
    );

    // Check for duplicate file names
    const existingFileNames = selectedFiles.map((file) => file.name);
    const duplicates = newFiles.filter((file) =>
      existingFileNames.includes(file.name)
    );

    if (duplicates.length > 0) {
      setDuplicateFiles(duplicates.map((file) => file.name));
      setOpenDuplicateModal(true);
    }

    // Filter out duplicates before adding
    const uniqueFiles = newFiles.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    // Limit the total number of files to 3
    if (selectedFiles.length + uniqueFiles.length > 3) {
      uniqueFiles.splice(3 - selectedFiles.length);
    }

    setSelectedFiles([...selectedFiles, ...uniqueFiles]);

    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
    setDuplicateFiles([]);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <input
        accept="application/pdf"
        style={{ display: "none" }}
        id="file-upload"
        multiple
        type="file"
        onChange={handleFileChange}
        disabled={selectedFiles.length >= 3 || chatbotLoading}
      />
      <label htmlFor="file-upload">
        <Tooltip
          title={
            selectedFiles.length >= 3
              ? "Please upload new files in a separate message."
              : "Upload PDF files"
          }
        >
          <span>
            <IconButton
              component="span"
              disabled={selectedFiles.length >= 3 || chatbotLoading}
            >
              <AttachFileIcon />
            </IconButton>
          </span>
        </Tooltip>
      </label>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your message..."
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        multiline
        disabled={chatbotLoading}
        maxRows={5}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSend}
              disabled={
                (!input.trim() && selectedFiles.length === 0) || chatbotLoading
              }
              sx={{
                minWidth: 0,
                padding: 1,
                borderRadius: "50%",
              }}
            >
              <SendIcon />
            </Button>
          ),
          sx: {
            backgroundColor: theme.palette.background.dark,
            border: "none",
            width: "100%",
            margin: "0 auto",
            py: 1,
            px: 2,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "32px",
          },
          "& .MuiInputBase-root": {
            mx: 0,
          },
        }}
      />

      {/* Modal for Duplicate Files */}
      <Dialog open={openDuplicateModal} onClose={handleCloseDuplicateModal}>
        <DialogTitle>Duplicate File Detected</DialogTitle>
        <DialogContent>
          <Typography>The following file(s) are already attached:</Typography>
          <ul>
            {duplicateFiles.map((fileName, idx) => (
              <li key={idx}>{fileName}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDuplicateModal} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessageInput;
